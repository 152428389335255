import React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as Logo } from '../assets/logo.svg';
import NewsletterForm from './NewsletterForm';

const Footer = () => (
  <footer>
    <div className="max-w-6xl mx-auto px-4 sm:px-6">
      <div className="grid sm:grid-cols-12 gap-8 py-8 md:py-12 border-t border-gray-200">
        <div className="sm:col-span-12 lg:col-span-3">
          <div className="mb-2">
            <Link to="/" className="inline-block" aria-label="Cruip">
              <Logo />
            </Link>
          </div>
          <div className="text-sm leading-tight text-gray-600">
            <Link
              to="/terms"
              className="text-gray-600 hover:text-gray-900 hover:underline transition duration-150 ease-in-out"
            >
              Terms
            </Link>
            {' · '}
            <Link
              to="/privacy"
              className="text-gray-600 hover:text-gray-900 hover:underline transition duration-150 ease-in-out"
            >
              Privacy Policy
            </Link>
          </div>
        </div>
        <div className="sm:col-span-6 md:col-span-3 lg:col-span-2">
          <h6 className="text-gray-800 font-medium mb-4">Products</h6>
          <ul className="text-sm leading-tight">
            <li className="mb-3">
              <Link
                to="/products/google-classroom-voice"
                className="text-gray-600 hover:text-gray-900 transition duration-150 ease-in-out"
              >
                <i>for</i>  Google Classroom™ (plus Docs™ + Gmail™)
              </Link>
            </li>
            <li className="mb-3">
              <Link
                to="/products/google-docs-voice"
                className="text-gray-600 hover:text-gray-900 transition duration-150 ease-in-out"
              >
                <i>for</i>  Google Docs™
              </Link>
            </li>
            <li className="mb-3">
              <Link
                to="/products/gmail-voice"
                className="text-gray-600 hover:text-gray-900 transition duration-150 ease-in-out"
              >
                <i>for</i>  Gmail™
              </Link>
            </li>
            <li className="mb-3">
              <Link
                to="/record"
                className="text-gray-600 hover:text-gray-900 transition duration-150 ease-in-out"
              >
                <i>for</i>  Web
              </Link>
            </li>
          </ul>
        </div>
        <div className="sm:col-span-6 md:col-span-3 lg:col-span-2">
          <h6 className="text-gray-800 font-medium mb-4">Resources</h6>
          <ul className="text-sm leading-tight">
            <li className="mb-3">
              <a
                href="https://mail.google.com/mail/u/0/?fs=1&tf=cm&source=mailto&su=Web+Support+Issue+via+Help+Link&to=support@beepaudio.com"
                target="_blank"
                className="text-gray-600 hover:text-gray-900 transition duration-150 ease-in-out"
                rel="nofollow noreferrer"
              >
                Help
              </a>
            </li>
            <li className="mb-3">
              <a
                href="https://mail.google.com/mail/u/0/?fs=1&tf=cm&source=mailto&su=Web+Support+Issue+via+Help+Link&to=support@beepaudio.com"
                target="_blank"
                className="text-gray-600 hover:text-gray-900 transition duration-150 ease-in-out"
                rel="nofollow noreferrer"
              >
                Contact Us
              </a>
            </li>
          </ul>
        </div>
        <div className="sm:col-span-6 md:col-span-3 lg:col-span-2">
          <h6 className="text-gray-800 font-medium mb-4">Company</h6>
          <ul className="text-sm leading-tight">
            <li className="mb-3">
              <Link
                to="/"
                className="text-gray-600 hover:text-gray-900 transition duration-150 ease-in-out"
              >
                Home
              </Link>
            </li>
            <li className="mb-3">
              <Link
                to="/about-us"
                className="text-gray-600 hover:text-gray-900 transition duration-150 ease-in-out"
              >
                About Us
              </Link>
            </li>
          </ul>
        </div>

        {/* 5th block */}
        <div className="sm:col-span-6 md:col-span-3 lg:col-span-3">
          <h6 className="text-gray-800 font-medium mb-2">Subscribe</h6>
          <p className="text-sm leading-tight text-gray-600 mb-4">
            Get sneak peeks of what we’re working on and exclusive early access.
          </p>
          <NewsletterForm />
        </div>
      </div>

      <div className="md:flex md:items-center md:justify-between py-4 md:py-8 border-t border-gray-200">
        <ul className="flex justify-center md:justify-end mb-4 md:order-1 md:ml-4 md:mb-0">
          <li>
            <a
              href="https://twitter.com/beepaudio"
              className="flex justify-center items-center text-gray-600 hover:text-gray-900 bg-white hover:bg-white-100 rounded-full transition duration-150 ease-in-out"
              aria-label="Twitter"
              target="_blank"
              rel="nofollow noreferrer"
            >
              <svg
                className="w-8 h-8 fill-current"
                viewBox="0 0 32 32"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M24 11.5c-.6.3-1.2.4-1.9.5.7-.4 1.2-1 1.4-1.8-.6.4-1.3.6-2.1.8-.6-.6-1.5-1-2.4-1-1.7 0-3.2 1.5-3.2 3.3 0 .3 0 .5.1.7-2.7-.1-5.2-1.4-6.8-3.4-.3.5-.4 1-.4 1.7 0 1.1.6 2.1 1.5 2.7-.5 0-1-.2-1.5-.4 0 1.6 1.1 2.9 2.6 3.2-.3.1-.6.1-.9.1-.2 0-.4 0-.6-.1.4 1.3 1.6 2.3 3.1 2.3-1.1.9-2.5 1.4-4.1 1.4H8c1.5.9 3.2 1.5 5 1.5 6 0 9.3-5 9.3-9.3v-.4c.7-.5 1.3-1.1 1.7-1.8z" />
              </svg>
            </a>
          </li>
          <li className="ml-4">
            <a
              href="https://instagram.com/beep.audio"
              className="flex justify-center items-center text-gray-600 hover:text-gray-900 bg-white hover:bg-white-100 rounded-full transition duration-150 ease-in-out"
              aria-label="Instagram"
              target="_blank"
              rel="nofollow noreferrer"
            >
              <svg
                width="32"
                height="32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M19.924 8h-8.298C9.082 8 7 10.126 7 12.724v8.102c0 2.598 2.082 4.724 4.626 4.724h8.298c2.544 0 4.626-2.126 4.626-4.724v-8.102C24.55 10.126 22.468 8 19.924 8zm3.055 12.708c0 1.8-1.441 3.271-3.202 3.271h-8.004c-1.761 0-3.202-1.472-3.202-3.27v-7.867c0-1.8 1.441-3.271 3.202-3.271h8.004c1.761 0 3.202 1.472 3.202 3.27v7.867z"
                  fill="#666"
                />
                <path
                  d="M15.774 12.27c-2.437 0-4.412 2.017-4.412 4.506 0 2.489 1.975 4.506 4.412 4.506s4.412-2.017 4.412-4.506c0-2.489-1.975-4.506-4.412-4.506zm0 7.413c-1.572 0-2.847-1.301-2.847-2.907s1.275-2.907 2.847-2.907 2.847 1.301 2.847 2.907-1.275 2.907-2.847 2.907zM20.424 13.045c.55 0 .996-.456.996-1.018s-.446-1.017-.996-1.017-.997.455-.997 1.017c0 .562.446 1.018.997 1.018z"
                  fill="#666"
                />
              </svg>
            </a>
          </li>
          <li className="ml-4">
            <a
              href="https://www.facebook.com/beep.voice.notes"
              className="flex justify-center items-center text-gray-600 hover:text-gray-900 bg-white hover:bg-white-100 rounded-full transition duration-150 ease-in-out"
              aria-label="Facebook"
              target="_blank"
              rel="nofollow noreferrer"
            >
              <svg
                className="w-8 h-8 fill-current"
                viewBox="0 0 32 32"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M14.023 24L14 17h-3v-3h3v-2c0-2.7 1.672-4 4.08-4 1.153 0 2.144.086 2.433.124v2.821h-1.67c-1.31 0-1.563.623-1.563 1.536V14H21l-1 3h-2.72v7h-3.257z" />
              </svg>
            </a>
          </li>
        </ul>

        <div className="text-xs text-gray-600 md:text-left text-center mr-4">
          &copy;
          {`Copyright ${new Date().getFullYear()}, JoWoCo, LLC. All rights reserved.`}
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;
