/* eslint-disable */

import React, { useState, useRef, useEffect } from 'react';
import { ReactComponent as StanfordLogo } from '../assets/carousel/stanford-logo.svg';
import { ReactComponent as HarvardLogo } from '../assets/carousel/harvard-logo.svg';
import { ReactComponent as TexasAMLogo } from '../assets/carousel/texas-a-and-m-logo.svg';
import { ReactComponent as MITLogo } from '../assets/carousel/MIT-logo.svg';
import { ReactComponent as GlobantLogo } from '../assets/carousel/globant-Logo.svg';

import '../styles/carousel.scss';

const Carousel = () => {
  const [activeSlide, setActiveSlide] = useState(0);
  const timer = useRef(null);
  const [slides] = useState([
    {
      author: 'Lara Collins',
      appUser: 'Beep for Gmail™ User',
      content: 'Beep is a gamechanger!'
    },
    {
      author: 'Brenda Thompson',
      appUser: 'Beep for Google Classroom™ User',
      content:
        'Beep has been invaluable in communicating with students during lockdown and quarantines.'
    },
    {
      author: 'Juan Carlos',
      appUser: 'Beep for Google Docs™ User',
      content:
        "Thank you so much for creating this tool. I've been using it with contractors to provide feedback on articles they've written."
    }
  ]);

  useEffect(() => {
    timer.current = setInterval(() => updateSlide(activeSlide + 1), 5000);
    return () => clearInterval(timer.current);
  }, [activeSlide]);

  const minIndex = 0;
  const maxIndex = slides.length - 1;

  const nextSlide = () => updateSlide(activeSlide + 1);
  const previousSlide = () => updateSlide(activeSlide - 1);

  const updateSlide = (newIndex) => {
    if (newIndex < minIndex) {
      return setActiveSlide(maxIndex);
    }

    if (newIndex > maxIndex) {
      return setActiveSlide(minIndex);
    }

    setActiveSlide(newIndex);
  };

  return (
    <section className="relative">
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-12 md:py-20">
          <div className="max-w-3xl mx-auto text-center md:pb-16">
            <h2 className="h2 mb-4">
              Trusted by 100,000s of users
            </h2>
            <p className="text-xl text-gray-600" data-aos="zoom-y-out">
              From teachers to customer service reps, people around the globe
              use Beep to save time and communicate more effectively
            </p>
          </div>
          {/* <div className="max-w-sm md:max-w-4xl mx-auto grid gap-2 grid-cols-4 md:grid-cols-5">
            <div className="flex items-center justify-center py-2 col-span-2 md:col-auto">
              <StanfordLogo className="max-w-full fill-current text-gray-400" />
            </div>
            <div className="flex items-center justify-center py-2 col-span-2 md:col-auto">
              <HarvardLogo className="max-w-full fill-current text-gray-400" />
            </div>
            <div className="flex items-center justify-center py-2 col-span-2 md:col-auto">
              <TexasAMLogo className="max-w-full fill-current text-gray-400" />
            </div>
            <div className="flex items-center justify-center py-2 col-span-2 md:col-auto">
              <MITLogo className="max-w-full fill-current text-gray-400" />
            </div>
            <div className="flex items-center justify-center py-2 col-span-2 md:col-auto col-start-2 col-end-4">
              <GlobantLogo className="max-w-full fill-current text-gray-400" />
            </div>
          </div> */}

          <div className="max-w-3xl mx-auto">
            <div className="relative" data-aos="zoom-y-out">
              <div className="relative flex items-center justify-center border-2 h-56 p-5 border-gray-200 rounded bg-white z-10">
                <div className="absolute top-0 -mt-8 left-1/2 transform -translate-x-1/2">
                {/* <svg xmlns="http://www.w3.org/2000/svg" width="70" height="70" fill="none"><g clip-path="url(#a)"><path fill="#fff" d="M0 0h70v70H0z"/><g clip-path="url(#b)"><path fill="#EEE" d="M37.917 42.948c0-14.995 11.351-30.68 29.166-34.198l2.87 6.2c-6.46 2.436-12.142 10.915-12.775 16.76C64.444 32.853 70 39.139 70 46.728c0 9.28-7.537 14.522-15.164 14.522-8.794 0-16.92-6.723-16.92-18.302ZM0 42.948C0 27.953 11.352 12.268 29.167 8.75l2.87 6.2c-6.46 2.436-12.142 10.915-12.775 16.76 7.265 1.143 12.821 7.429 12.821 15.018 0 9.28-7.536 14.522-15.163 14.522C8.126 61.25 0 54.527 0 42.948Z"/></g></g><defs><clipPath id="a"><path fill="#fff" d="M0 0h70v70H0z"/></clipPath><clipPath id="b"><path fill="#fff" d="M0 0h70v70H0z"/></clipPath></defs></svg>                 */}
                </div>

                {slides.map((slide, index) => (
                  <div
                    key={index}
                    className={`carousel-slide${
                      activeSlide === index ? '--active' : '--hidden'
                    }`}
                  >
                    <blockquote className="text-xl font-medium mb-4">
                      {slide.content}
                    </blockquote>
                    <cite className="block font-bold text-lg not-italic mb-1">
                      {slide.author}
                    </cite>
                    <div className="text-gray-600">
                      <span>{slide.appUser}</span>
                    </div>
                  </div>
                ))}
              </div>
              <div className="absolute inset-0 flex items-center justify-between">
                <button
                  className="relative z-20 w-16 h-16 p-1 flex items-center justify-center bg-white rounded-full border border-gray-200 shadow-md hover:shadow-lg transform -translate-x-2 md:-translate-x-1/2 curso-pointer"
                  onClick={previousSlide}
                >
                  <span className="sr-only">Previous</span>
                  <svg
                    className="w-4 h-4 fill-current text-gray-500"
                    viewBox="0 0 16 16"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M6.7 14.7l1.4-1.4L3.8 9H16V7H3.8l4.3-4.3-1.4-1.4L0 8z" />
                  </svg>
                </button>
                <button
                  className="relative z-20 w-16 h-16 p-1 flex items-center justify-center bg-white rounded-full border border-gray-200 shadow-md hover:shadow-lg transform translate-x-2 md:translate-x-1/2 cursor-pointer"
                  onClick={nextSlide}
                >
                  <span className="sr-only">Next</span>
                  <svg
                    className="w-4 h-4 fill-current text-gray-500"
                    viewBox="0 0 16 16"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M9.3 14.7l-1.4-1.4L12.2 9H0V7h12.2L7.9 2.7l1.4-1.4L16 8z" />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Carousel;
